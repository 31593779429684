<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">{{ $t('BILLS.REQUEST_BILL') }}</h3>
            </div>
          </div>
          <div class="card-body pt-0">
            <div v-if="!isDevicesLoaded" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <div v-else-if="isDevicesLoaded && devices.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
            <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                  <p class="font-weight-bold" style="font-size: 24px;">No transactions.</p>
                  <p style="font-size: 16px;">There are currently no transactions.</p>
                </div>
            </div>
          </div>
          <div v-else-if="isDevicesLoaded && devices.length > 0" class="card-body pt-0">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="font-weight-bolder"
                    >{{ $t('GENERAL.DEVICE') }}*</label
                  >
                  <vSelect
                    class="form-control form-control--modules-select"
                    :placeholder="$t('BILLS.SELECT_DEVICES')"
                    v-model="selectedDevice"
                    :options="devices"
                    label="name"
                  >
                    <template #option="{ name, location, tags }">
                      <span class="mr-3">{{ name }}</span>
                      <span class="badge badge-primary mr-3">{{
                        location.title
                      }}</span>
                      <span v-if="tags[0]" class="badge badge-secondary mr-3">{{
                        tags[0].name
                      }}</span>
                      <span
                        v-if="location.lessor"
                        class="badge badge-secondary"
                        >{{ location.lessor.name }}</span
                      >
                    </template>
                  </vSelect>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label class="font-weight-bolder"
                    >{{ $t('GENERAL.AMOUNT') }}*</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      v-model="amount"
                      class="form-control form-control-lg"
                      :placeholder="$t('GENERAL.AMOUNT')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="font-weight-bolder"
                    >{{ $t('BILLS.CREATE_TRANSACTION') }}*</label
                  >
                  <VtSwitch
                    @checkedEvent="toggleCreateTransaction"
                    :is-checked="createTransaction"
                    classes="switch-sm switch-icon switch-outline switch-primary"
                    name="createTransaction"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <button
                  :disabled="!selectedDevice || !amount"
                  @click.prevent="send"
                  class="btn btn-primary btn-block font-weight-bold"
                >
                  {{ $t('GENERAL.SEND') }}
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import axios from 'axios'

export default {
  name: 'BillsRequest',
  components: {
    vSelect,
    VtSwitch,
  },
  data() {
    return {
      devices: [],
      isDevicesLoaded: false,
      selectedDevice: null,
      amount: null,
      companyOib: null,
      billNumber: null,
      businessSpaceLabel: null,
      isuNumber: null,
      imei: null,
      createTransaction: true,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bill Request', route: '/transactions/list' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get('devices', '?pagination=true&new=1&select=flags,timestamps,related_ids,other').then(({ data }) => {
        this.devices = [...data['hydra:member']]
        this.isDevicesLoaded = true
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps,related_ids,other`
      ).then((response) => {
        this.devices = [...response.data['hydra:member']]
        this.isDevicesLoaded = true
      })
    }
  },
  methods: {
    toggleCreateTransaction() {
      this.createTransaction = !this.createTransaction
    },
    send() {
      this.companyOib = this.currentUserCompanyOib
      this.imei = this.selectedDevice.logger.imei
      this.billNumber = this.selectedDevice.billNumber
      this.businessSpaceLabel = this.selectedDevice.businessSpaceLabel
      this.isuNumber = this.selectedDevice.isuNumber

      axios
        .get(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/fiscal/${this.amount}/${this.companyOib}/${this.billNumber}/${this.businessSpaceLabel}/${this.isuNumber}/${this.imei}/${this.createTransaction}`
        )
        .then((response) => {
          if (response.status === 200) {
            this.selectedDevice = null
            this.amount = null
            this.companyOib = null
            this.billNumber = null
            this.businessSpaceLabel = null
            this.isuNumber = null
            this.imei = null

            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'Uspješno.',
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--logger-select,
  &--modules-select,
  &--users-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
